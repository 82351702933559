import classNames from 'classnames';
import styles from './styles.module.css';
export const DocumentViewer = ({ url, className }: { url: string; className?: string }) => {
  return (
    <iframe
      className={classNames(styles.iframe, className)}
      src={`https://docs.google.com/gview?url=${url}&embedded=true`}
    ></iframe>
  );
};
