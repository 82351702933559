import { useEffect, useRef, useState } from 'react';

import { Delete, ImageOutlined } from '@mui/icons-material';

import styles from './styles.module.css';

import { IconButton, Overlay } from './styled';
import { Tooltip } from './tooltip';
import CenteredProgress from './centered-progress';
import { DocumentViewer } from './document-viewer';
import { File } from 'buffer';
import classNames from 'classnames';

interface Props {
  originalFileUrl?: string;
  wIconEmptyState?: boolean;
  namePreview?: boolean;
  uploading?: boolean;
  document?: boolean;
  image?: boolean;
  video?: boolean;
  onChange: (selectedFile: any) => void;
  deleteImage: () => void;
}

export const ImageUpload = ({
  originalFileUrl,
  namePreview,
  wIconEmptyState,
  uploading,
  document: isDocument,
  image,
  video,
  onChange,
  deleteImage,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState(originalFileUrl || '');
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    onChange(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setFileUrl(originalFileUrl || '');
    setSelectedFile(null);
  }, [originalFileUrl]);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);

    // Generate a preview URL for the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result as string);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };
  const fileName = selectedFile?.name || originalFileUrl?.split('/')?.pop();

  return (
    <>
      {fileUrl ? (
        <div
          className={classNames(styles.uploadPreview, {
            [styles.textOnly]: namePreview || (isDocument && selectedFile),
          })}
        >
          {uploading && (
            <>
              <CenteredProgress className={styles.uploadProgress} size={20} />
              <Overlay style={{ background: '#fff', opacity: 0.8 }} showMobile fullHeight absolute />
            </>
          )}
          {(namePreview || (isDocument && selectedFile)) && fileName}
          {image && <img src={fileUrl} alt="Preview" />}
          {isDocument && !namePreview && !selectedFile && <DocumentViewer url={fileUrl} />}
          {!uploading && (
            <Tooltip
              title={isDocument ? 'Delete document' : video ? 'Delete video' : 'Delete image'}
              placement="bottom-left"
              offset={{ top: 5 }}
            >
              <IconButton
                withBackground
                withBorder
                light
                onClick={() => {
                  deleteImage();
                  setFileUrl('');
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ) : (
        <>
          {wIconEmptyState ? (
            <button className={styles.uploadIconButton} onClick={handleClick}>
              <ImageOutlined />
            </button>
          ) : (
            <button className={styles.uploadButton} onClick={handleClick}>
              Upload a file
            </button>
          )}
          <input
            type="file"
            accept={isDocument ? '.doc,.docx,.pdf' : image ? 'image/*' : video ? 'video/*' : undefined}
            onChange={handleFileChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
          />
        </>
      )}
    </>
  );
};
